import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Component({
    selector: 'hb-custom-snackbar',
    templateUrl: './custom-snackbar.component.html',
    standalone: true,
    imports: [
        MatButtonModule 
    ]
})
export class CustomSnackBar{

    constructor(private snackBarRef: MatSnackBarRef<CustomSnackBar>, @Inject(MAT_SNACK_BAR_DATA) public data: any, private location: Location) { }

    public close(): void {
        this.snackBarRef.dismiss();
        this.location.back();
    }
}